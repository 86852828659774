<template>
  <div>
    <div class="form-group">
      <input
        type="file"
        class="form-control-file"
        aria-describedby="fileHelp"
        id="toupload"
        name="toupload"
        :disabled="upload_in_progress"
        @change="selected_file_changed"
        :multiple="$props.allow_multiple_files" />
      <br />
      <small class="form-text text-muted">
        <span v-html="$props.helper_text"></span>
      </small>
    </div>

    <button
      v-if="ready_to_upload && !$props.upload_btn_hidden"
      @click.prevent="upload"
      class="btn btn-primary"
      type="button"
      :disabled="upload_in_progress">
      <span v-if="upload_in_progress" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      &nbsp; Upload
    </button>
  </div>
</template>

<script>
var AWS = require('aws-sdk');

const DEBUG_MODE = process.env.VUE_APP_DEBUG_MODE == 'true';
const PUBLIC_BUCKET = process.env.VUE_APP_PUBLIC_BUCKET;
const COGNITO_IDENTITY_POOL_ID = process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID;
const COGNITO_REGION = process.env.VUE_APP_COGNITO_REGION;

export default {
  props: {
    uid: {
      type: String,
      required: true,
    },
    allow_multiple_files: {
      type: Boolean,
      default: false,
    },
    helper_text: {
      type: String,
      required: false,
    },
    upload_btn_hidden: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      upload_in_progress: false,
      ready_to_upload: false,

      app_code: 'pdflover',
      upload_id: null, // given by user (ie project_id)
      batch_uid: null, // unique identifier for every upload attempt

      all_files_to_upload: [],
      uploads_completed: null,
      global_upload_progress: {},

      file_uploads_in_ui: [],
    };
  },
  created() {
    var self = this;
    self.upload_id = self.$props.uid;
  },
  methods: {
    gr_to_en_translate: function (gr_str) {
      let final_translation = gr_str.toLowerCase();

      let alphabet_map = {
        'α': 'a',
        'ά': 'a',
        'β': 'b',
        'γ': 'g',
        'δ': 'd',
        'ε': 'e',
        'έ': 'e',
        'ζ': 'z',
        'η': 'i',
        'ή': 'i',
        'θ': 'th',
        'ι': 'i',
        'ί': 'i',
        'κ': 'k',
        'λ': 'l',
        'μ': 'm',
        'ν': 'n',
        'ξ': 'ks',
        'ο': 'o',
        'ό': 'o',
        'π': 'p',
        'ρ': 'r',
        'σ': 's',
        'ς': 's',
        'τ': 't',
        'υ': 'u',
        'ύ': 'u',
        'φ': 'f',
        'χ': 'x',
        'ψ': 'ps',
        'ω': 'w',
        'ώ': 'w',
        ' ': '_',
        '.': '.',
      };
      let alphabet_keys = Object.keys(alphabet_map);
      let en_letters = [
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
      ];
      let numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

      let gr_chars = [...final_translation];
      let en_chars = [];
      console.log(gr_chars, en_chars);

      let cchar = '';
      for (let i = 0; i < gr_chars.length; i++) {
        cchar = gr_chars[i];
        if (alphabet_keys.includes(cchar)) {
          en_chars.push(alphabet_map[cchar]);
        } else if (en_letters.includes(cchar)) {
          en_chars.push(cchar);
        } else if (numbers.includes(cchar)) {
          en_chars.push(cchar);
        } else {
          en_chars.push('_');
        }
      }

      // console.log(en_chars);
      return en_chars.join('');
    },
    cleanup_filename: function (input_file_name) {
      var self = this;

      let final_filename = input_file_name;

      // translate gr to en [greeklish]
      final_filename = self.gr_to_en_translate(final_filename);

      //remove empty spaces
      final_filename = final_filename.replace(/\s+/g, '');

      //remove non ascii
      final_filename = final_filename.replace(/[^A-Za-z0-9._]/g, '');

      //remove some special chars causing issues in convertion
      final_filename = final_filename.replace('(', '');
      final_filename = final_filename.replace(')', '');
      final_filename = final_filename.replace('#', '');
      final_filename = final_filename.replace('!', '');

      return final_filename;
    },
    selected_file_changed: function (event) {
      var self = this;
      if (DEBUG_MODE) {
        console.log('new file selected!', event.target.files);
      }

      if (event.target.files.length > 0) {
        self.ready_to_upload = true;
        self.all_files_to_upload = event.target.files;
      } else {
        // no file selected from browse menu
        self.ready_to_upload = false;
        self.all_files_to_upload = [];
      }

      self.$emit('filesSelected', self.all_files_to_upload);
    },
    upload: function () {
      var self = this;
      self.upload_in_progress = true;
      self.$emit('uploadStart');
      self.$emit('uploadProgress', '<br>Connecting to server..');

      self.batch_uid = (Math.random() + '').split('.')[1].slice(0, 7);
      self.uploads_completed = [];

      // Initialize the Amazon Cognito credentials provider
      AWS.config.region = COGNITO_REGION;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: COGNITO_IDENTITY_POOL_ID,
      });

      for (let i = 0; i < self.all_files_to_upload.length; i++) {
        self.global_upload_progress[i] = 'File [' + (i + 1) + '], Completed: 0%';
      }

      for (let i = 0; i < self.all_files_to_upload.length; i++) {
        var file = self.all_files_to_upload[i];

        var ts_start = +new Date();
        var ts_end = 0;

        let file_uid = (Math.random() + '').split('.')[1].slice(0, 7);

        // Upload the File
        // [APP_CODE]_[BATCH_UID]_[FILE_UID]_[CLEANED_FILENAME]
        let uuid_filename = [self.app_code, self.batch_uid, file_uid, self.cleanup_filename(file.name)].join('_');
        // if(DEBUG_MODE){console.log(uuid_filename);}

        self.all_files_to_upload[i]['original_filename'] = file.name;
        self.all_files_to_upload[i]['uuid_filename'] = uuid_filename;
        self.all_files_to_upload[i]['uuid_bucket'] = PUBLIC_BUCKET;
        self.all_files_to_upload[i]['uuid_path'] = `uploads/${self.upload_id}/${uuid_filename}`;
        if (DEBUG_MODE) {
          console.log(self.all_files_to_upload[i]);
        }

        var bucket = new AWS.S3({
          params: {
            Bucket: PUBLIC_BUCKET,
          },
          httpOptions: {
            // https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#upload-property
            // both in milliseconds
            timeout: 20 * 60 * 1000,
            connectTimeout: 20 * 60 * 1000,
          },
        });
        var params = {
          Key: `uploads/${self.upload_id}/${uuid_filename}`,
          ContentType: file.type,
          Body: file,
        };
        var options = { partSize: 20 * 1024 * 1024, queueSize: 3 }; // 20mb ,concurrency = 30
        bucket
          .upload(params, options)
          .on('httpUploadProgress', function (evt) {
            // console.log('Progress:', evt.loaded, '/', evt.total);
            self.global_upload_progress[i] =
              'File [' + (i + 1) + '], Completed: ' + ((100 * evt.loaded) / evt.total).toFixed(2) + '%';

            let upload_progress_text = '<br>';
            for (let i = 0; i < self.all_files_to_upload.length; i++) {
              upload_progress_text += self.global_upload_progress[i] + '<br>';
            }

            self.$emit('uploadProgress', upload_progress_text);
          })
          .send(function (err, data) {
            // console.log(err, data);
            if (err == null) {
              ts_end = +new Date();
              console.log(
                `Completed [${i}] [${self.all_files_to_upload[i].name}] in ${(ts_end - ts_start) / 1000} seconds`
              );

              self.uploads_completed.push(self.all_files_to_upload[i]);
              console.log('uploads_completed_so_far=', self.uploads_completed.length);

              if (self.uploads_completed.length == self.all_files_to_upload.length) {
                console.log('all uploads completed!');
                self.upload_in_progress = false;
                self.ready_to_upload = false;

                let filesUploaded = [];
                for (let i = 0; i < self.uploads_completed.length; i++) {
                  let obj = {};
                  const element = self.uploads_completed[i];
                  // let file_url = `https://s3.amazonaws.com/${element['uuid_bucket']}/${element['uuid_path']}`;
                  // let file_url = `http://${element['uuid_bucket']}.s3.amazonaws.com/${element['uuid_path']}`;
                  // https://s3.[aws_region].amazonaws.com/[s3_bucket]/[file_key]
                  let file_url = `https://s3.${COGNITO_REGION}.amazonaws.com/${element['uuid_bucket']}/${element['uuid_path']}`;

                  // these fields are being brooadcasted
                  // bucket, key, name, url
                  obj.original_filename = element['original_filename'];
                  obj.file_bucket = element['uuid_bucket'];
                  obj.file_key = element['uuid_path'];
                  obj.file_name = element['uuid_filename'];
                  obj.file_url = file_url;
                  filesUploaded.push(obj);
                  console.log('files uploaded: ' + filesUploaded);
                }

                self.$emit('uploadSucceeded', filesUploaded);
              }
            } else {
              self.upload_in_progress = false;
              self.ready_to_upload = false;
              self.$emit('uploadFailed', err);
            }
          });
      }
    },
  },
};
</script>

<style></style>
