<template>
  <div class="container">
    <div class="bg-light p-5 rounded-lg mt-3 mb-3 m-1">
      <h1 class="display-4">
        PDF
        <img src="@/assets/heart.svg" alt="PDF Lover" height="60" />
      </h1>
      <h3>Convert any file to PDF, easily!</h3>
      <span>Select your file to convert, and leave the rest to us! Simply download your file when ready!</span>
    </div>

    <div class="row">
      <div class="col-md-12" id="upload-section">
        <!-- <h2>Select File</h2> -->
        <div class="form-group">
          <FileUploader
            class="mb-3 ml-3"
            :uid="upload_id"
            :helper_text="upload_helper_text"
            @uploadSucceeded="at_upload_success"
            @uploadFailed="at_upload_failure"
            @uploadProgress="at_upload_progress"
            @filesSelected="at_files_selected"
            :allow_multiple_files="false" />
          <span v-html="upload_status"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { gaLogger } from '@/services/firebase';
  import { appAlertBuilder } from '@/helpers/app.js';
  import FileUploader from '@/components/FileUploader';
  const main_endpoint = process.env.VUE_APP_MAIN_ENDPOINT;
  const debug = process.env.VUE_APP_DEBUG_MODE == 'true';

  export default {
    components: {
      FileUploader,
    },
    data() {
      return {
        upload_id: null,
        upload_status: null,
        upload_in_progress: null,
        upload_helper_text: 'File should be less than 500 MB.',
      };
    },
    methods: {
      at_files_selected: function (files_selected) {
        var self = this;
        if (debug) {
          console.log('at_files_selected=', files_selected);
        }
        self.upload_in_progress = true;
        self.upload_status = '';
      },
      at_upload_progress: function (upload_progress_text) {
        var self = this;
        self.upload_status = appAlertBuilder(
          'Please wait while file is being uploaded..' + upload_progress_text,
          'info'
        );
      },
      at_upload_failure: function (error_message) {
        var self = this;
        self.upload_in_progress = false;
        self.upload_status = appAlertBuilder('File upload failed. ' + error_message, 'danger');
        gaLogger('upload_failed', { message: error_message });
      },

      at_upload_success: async function (files_data) {
        var self = this;
        console.log(files_data);

        self.upload_in_progress = true;
        self.upload_status = appAlertBuilder(
          'Please wait for file conversion to complete (this usually takes less than 10 seconds).. <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>',
          'info'
        );

        await self.$store.dispatch('getValidToken');
        fetch(`${main_endpoint}/convert`, {
          method: 'POST',
          headers: {
            'Authorization': 'asdf', // No-Auth
          },
          body: JSON.stringify({
            bucket: files_data[0]['file_bucket'],
            key: files_data[0]['file_key'],
            name: files_data[0]['file_name'],
          }),
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            // console.log(response);
            let success = response.response.success;
            let message = response.response.message;
            let data = response.response.data;

            if (!success) {
              throw new Error(message);
            } else {
              self.upload_in_progress = false;
              self.upload_status = appAlertBuilder(
                `File has been converted successfully. <a href="${data.url}">DOWNLOAD</a>`,
                'success'
              );

              gaLogger('file_conversion_success');
            }
          })
          .catch(function (error) {
            console.log(error);
            self.upload_in_progress = false;
            self.upload_status = appAlertBuilder(error, 'danger');
            gaLogger('file_conversion_failed', { message: error });
          });
      },
      generate_upload_id: function () {
        let ts = new Date();
        const dd = ts.getDate();
        const dd_final = dd < 9 ? '0' + dd : dd;
        const mm = ts.getMonth() + 1;
        const mm_final = mm < 9 ? '0' + mm : mm;
        return `${ts.getFullYear()}_${mm_final}_${dd_final}`;
      },
    },
    created() {
      var self = this;

      self.upload_id = self.generate_upload_id();
      // console.log(self.upload_id);
    },
  };
</script>

<style></style>
